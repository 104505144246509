import React from 'react'
import {graphql, PageProps} from 'gatsby'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import {css} from '@emotion/react'

import NavigationProvider from '../providers/NavigationProvider'
import MDXProvider from '../providers/MDXProvider'
import {BaseVersionedPageContext, PageEntry, Mdx} from '../types'
import VersionsProvider from '../providers/VersionProvider'
import {articleMaxWidth} from '../constants'

import PageWithNav from './PageWithNav'

const articleCss = css`
  padding: 24px;
  max-width: ${articleMaxWidth}px;
  margin: auto;
`

interface DefaultProps extends PageProps {
  data: {
    mdx: Mdx
  }
  pageContext: BaseVersionedPageContext & {
    pageConfig: PageEntry
  }
}

export const pageQuery = graphql`
  query DefaultMarkdownQuery($id: String) {
    mdx(id: {eq: $id}) {
      id
      body
      tableOfContents
    }
  }
`

export default function Default({
  data: {mdx},
  pageContext: {version, versions, pageConfig, navList},
  ...props
}: DefaultProps): JSX.Element {
  return (
    <VersionsProvider versions={versions} version={version}>
      <NavigationProvider navList={navList} tableOfContents={mdx.tableOfContents.items}>
        <PageWithNav {...props} pageConfig={pageConfig}>
          <article css={articleCss}>
            <MDXProvider>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </article>
        </PageWithNav>
      </NavigationProvider>
    </VersionsProvider>
  )
}
